import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router";
import "./index.scss";
import { customBaseUrl, httpPost, httpGet } from "../../services/http";
import Logo from "../../assets/newlog.svg";
import { envConfig } from "../../utils/envConfig";
import { hideLoader, showLoader } from "../../utils/loader";
const LoanCallback = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
    const [sharedPostCode, setSharedPostCode] = useState(null);
  const [success, setSuccess] = useState(true);

 
  useEffect(() => {
    // const {
    //     location: { search },
    //   } = props;
    //   if (new URLSearchParams(search).get('acr_values') === 'otp') {
    //     const code = (new URLSearchParams(search).get("code"));
    //     const state = (new URLSearchParams(search).get("state"));
    //     const client = (new URLSearchParams(search).get("client"));
    //     verifyOtp(code, state, client);
    //     console.log('gets code', code);
    //     return;
    //   }
    
  }, []);

  const verifyOtp = async (code, trackingId, clientType) => {
    setLoading(true);
    showLoader();
    const res = await httpPost(
      `/no-auth/fetch-bvn-with-consent?code=${code}&trackingId=${trackingId}`,
      {},
      customBaseUrl.kycUrl,
      clientType
    );
    console.log('lloog', res)
    if (res?.status !== false) {
      hideLoader();
        setSuccess(true)
        setInterval(() => {
          window.close();
        }, 3000)
    } else {
      hideLoader();
        swal('Oops!', res?.message, 'error');
        // window.close()
        setInterval(() => {
          window.close();
        }, 3000)
        setSuccess(false)
      }
    };
    const handleClose = () => {
      window.close();
      history.push(`${envConfig.wayapayUrl}/bvn`);
    }
   return (
    <div id="login-bg">
        {success === true ?
      <div id="login-modal" className="my-auto modal-body-rs-log col-12">
        <div className="waya-modal-body-log">
          <div className="header-sec-modal-log">
            <img className="header-sec-logo-log mx-auto" style={{ width: '70%', height: 60 }} src={Logo} alt="" />
          </div>
          <h1 className="modal-header-data-log">Loan application successful!</h1> 
          <div className="text-center mt-3">
            <button className="btn btn-primary" onClick={handleClose}>Close</button>
          </div>
        </div>
      </div>:success === false? <div>Verification failed</div>: "Verifying"}
      
    </div>
  );
};

export default LoanCallback;
